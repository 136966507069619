<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="user.name"
              name="name"
              placeholder="Name"
              required
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="user.email"
              name="email"
              placeholder="Email"
              disabled
              required
            />

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="updateProfile"
          >
            Save changes
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {},
      profileFile: null,
    }
  },
  created() {
    this.user = this.$store.state.auth.user;
  },
  methods: {
    resetForm() {
      this.user.email = '';
      this.user.name = "";
    },
    updateProfile() {
      const response = this.$axios.post('/users/updateProfile', {
        name: this.user.name,
      }).then(resp => {
        this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Profile Updated',
                icon: 'CheckIcon',
                variant: 'success',
            },
        });
      }).catch(err => {
        console.error(err);
        this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Oops! Something went wrong',
                icon: 'AlertCircleIcon',
                variant: 'danger',
            },
        });
      });
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
